<template>
  <div class="circleintroduce">
    <div class="circleintroduce_center" v-if="intoducedata !== null">
      <!-- <h1>教研圈介绍页</h1> -->
      <nav>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            :to="{ name: `${showp}` }"
            v-if="showone == 1 || showone == 0"
          >
            {{ shown }}
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/circle' }" v-else>
            教研圈
          </el-breadcrumb-item>
          <el-breadcrumb-item
            @click.native="cutshow(0)"
            :to="{
              path: '/circleintroduce',
              query: { introId: this.$route.query.introId },
            }"
          >
            {{ intoducedata.researchCircleName }}
          </el-breadcrumb-item>
          <!-- <el-breadcrumb-item>{{intoducedata.researchCircleName}}</el-breadcrumb-item> -->
          <el-breadcrumb-item v-if="showtime == 1">
            教研圈公告
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="showtime == 2 && showvread !== null">
            {{ showvread.title || showvread.talkTitle }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </nav>
      <header>
        <div class="h_left">
          <img :src="intoducedata.logoUrl" alt />
          <div class="h_icon" v-if="showone != 1">
            <p class="h_p_like">
              <i class="like" v-if="!praiseFlag" @click="messagePraise"></i>
              <i class="likes" v-else @click="deletePraise"></i>
              <span>{{ intoducedata.praiseCount }}</span>
            </p>
            <p class="h_p_star">
              <i
                class="star"
                v-if="!collectionFlag"
                @click="messageCollection"
              ></i>
              <i class="stars" v-else @click="messageDeleteCollection"></i>
              <span>{{ intoducedata.collectionCount }}</span>
            </p>
          </div>
        </div>
        <div class="h_right">
          <p class="r_title">{{ intoducedata.researchCircleName }}</p>
          <p class="r_user">教研圈建立者：{{ intoducedata.createUserName }}</p>
          <p class="r_time">创建时间：{{ intoducedata.createTime }}</p>
          <p class="r_subject">学科：{{ intoducedata.subjectList }}</p>
          <p class="r_period">学段：{{ intoducedata.periodList }}</p>
          <p class="r_grade">年级：{{ intoducedata.gradeList }}</p>
          <div v-if="this.intoducedata.adminFlag == 0">
            <!-- 加入状态 0-申请加入待审核 1-审核不通过 2-审核通过 3-初始状态 -->
            <el-button
              type="primary"
              v-if="this.intoducedata.enterStatus == 0"
              disabled
            >
              正在审核中
            </el-button>
            <el-button
              type="primary"
              @click="enterCircle"
              v-if="
                this.intoducedata.enterStatus == 1 ||
                this.intoducedata.enterStatus == 3
              "
            >
              申请加入圈子
            </el-button>
            <el-button
              type="primary"
              disabled
              v-if="this.intoducedata.enterStatus == 2"
            >
              已加入教研圈
            </el-button>
          </div>
        </div>
      </header>
      <main v-if="showtime == 0">
        <div class="main_left">
          <div style="background: #ffffff">
            <p class="m_title">
              <span class="m_t_span">
                {{ intoducedata.researchCircleName }}
              </span>
              <span class="m_t_span" style="margin-left: 5px">话题贴吧</span>
            </p>
            <div class="m_list_null" v-if="this.showlist">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="m_list" v-else>
              <div class="m_listli" v-for="item in talkdata" :key="item.id">
                <div class="li_number">
                  <div class="li_count">{{ item.replayCount }}</div>
                  <div class="li_title" @click="cutshow(2, item)">
                    {{ item.title }}
                  </div>
                  <div class="li_user">
                    <i class="createuser"></i>
                    <span>{{ item.createUser }}</span>
                  </div>
                </div>
                <div class="li_reply">
                  <div class="lir_title">{{ item.replayContent }}</div>
                  <div class="lir_user">
                    <i class="createusers"></i>
                    <span>{{ item.replayUser }}</span>
                  </div>
                  <div class="lir_tiem">
                    <span>{{ item.replayTime }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!this.showlist">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="curpage"
                :page-size="pagesize"
                layout="total,prev, pager, next, jumper"
                :total="total"
              ></el-pagination>
            </div>
          </div>

          <!--  -->
          <div v-if="this.intoducedata.enterStatus == 2 || this.currentShow">
            <footer v-if="showone != 1">
              <p class="f_title">
                <i></i>
                <span>发布新话题</span>
              </p>
              <div class="f_i_title">
                <el-input
                  v-model="talkform.title"
                  placeholder="请输入标题"
                  size="mini"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="talkform.content"
                  placeholder="请输入内容"
                  size="mini"
                ></el-input>
              </div>
              <!-- <div style="margin: 10px 0">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  action
                  :on-change="handleChange"
                  :file-list="fileList"
                  :auto-upload="false"
                  :before-remove="handleBeforeRemove"
                >
                  <el-button slot="trigger" size="mini" type="primary">
                    选取文件
                  </el-button>
                  &lt;!&ndash;
                  <el-select
                    v-model="talkform.type"
                    placeholder="请选择"
                    size="mini"
                    style="margin-left: 10px"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option> </el-select
                  >&ndash;&gt; &lt;!&ndash;
                  <el-button
                    style="margin-left: 10px"
                    size="small"
                    type="success"
                    @click="submitUpload"
                    >上传到服务器</el-button
                  >
                  &ndash;&gt; &lt;!&ndash;
                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件，且不超过500kb
                  </div>
                  &ndash;&gt;
                </el-upload>
              </div> -->

              <UploadSlice
                :action="uploadInfoSlice.actionChunk"
                :headers="uploadInfoSlice.headers"
                :limit="uploadInfoSlice.limit"
                :accept="uploadInfoSlice.accept"
                :show-file-list="true"
                multiple
                cancelable
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                :on-cancel="handleCancel"
                :on-upload-pre="handleUploadPre"
                :on-upload-merge="handleUploadMerge"
                :on-form-data="genFormData"
                :showFileList="showFileList"
                @editFileList="editFileList"
                style="margin: 10px 0"
              />
              <!-- <div>
                <h1>
                  <input type="file" @change="handleFileChange" />
                  <button @click="handleUpload">上传</button>
                </h1>
                <span>{{
                  allChunksUploaded
                    ? "完成"
                    : "上传进度：" + uploadProcess + "%"
                }}</span>
              </div> -->
              <el-button
                @click="saveCircleTalk"
                size="mini"
                type="primary"
                v-loading.fullscreen.lock="fullscreenLoading"
                >发布
              </el-button>
            </footer>
          </div>
        </div>
        <div class="main_right">
          <div class="r_introduce">
            <p class="r_i_title">教研圈介绍</p>
            <p class="r_i_content">{{ intoducedata.content }}</p>
            <p style="height: 1px"></p>
          </div>
          <div class="r_notice">
            <!-- {{noticedata}} -->
            <div class="r_n_title">
              <div class="r_n_up">
                <p class="r_n_up_title">教研圈公告</p>
                <p class="r_n_up_more" @click="cutshow(1)">查看更多 &gt;</p>
              </div>
              <div class="r_n_down">
                <ul>
                  <li v-for="item in noticedata" :key="item.id">
                    <span class="item_sapn" @click="gotopath(item.id)">
                      {{ item.title }}
                    </span>
                    <span class="items_span">{{ item.createTime }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="r_member">
            <p class="r_m_title">
              教研圈成员 &nbsp; ({{ intoducedata.memberCount }}人)
            </p>
            <div class="r_m_createuser">
              <p class="r_m_logo">
                <i></i>
                <span>创建者</span>
              </p>
              <div class="user_mmember">
                <div
                  class="member_flex"
                  v-if="memberdata.createUser.avager == null"
                >
                  <img src="../../../assets/components/userin.png" alt />
                  <!-- <img :src="memberdata.createUser.avager" alt=""> -->
                </div>
                <div class="member_flex" v-else>
                  <!-- <img src="../../../assets/components/userin.png" alt /> -->
                  <img :src="memberdata.createUser.avager" alt />
                </div>
                <p>{{ memberdata.createUser.userName }}</p>
              </div>
            </div>
            <div class="r_m_createmember">
              <p class="r_m_logo">
                <i></i>
                <span>成员</span>
              </p>
              <div class="r_m_oop">
                <div
                  v-for="item in memberdata.memberList"
                  :key="item.id"
                  style="margin-right: 5px"
                >
                  <div class="user_mmember">
                    <!-- {{memberdata.memberList}} -->
                    <div class="member_flex" v-if="item.avager == null">
                      <img src="../../../assets/components/userin.png" alt />
                      <!-- <img :src="memberdata.createUser.avager" alt=""> -->
                    </div>
                    <div class="member_flex" v-else>
                      <!-- <img src="../../../assets/components/userin.png" alt /> -->
                      <img :src="memberdata.createUser.avager" alt />
                    </div>
                    <p>{{ item.userName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div class="main_one" v-if="showtime == 1">
        <notice></notice>
      </div>
      <div class="main_one" v-if="showtime == 2">
        <notices
          :fabutitle="intoducedata.researchCircleName"
          :fabuid="showvread.id"
          @cuthtml="cutshows"
          :cutstatus="this.intoducedata.enterStatus"
          :cutadmin="this.intoducedata.adminFlag"
          :showone="this.showone"
          :currentShow="this.currentShow"
        ></notices>
      </div>
    </div>
  </div>
</template>
<script>
import UploadSlice from "@/components/UploadSlice.vue";
import { Message } from "element-ui";
import notice from "./noticeinfo.vue";
import notices from "./noticefabu.vue";
import Axios, { AxiosResponse } from "axios";
import _ from "lodash";
import CryptoJS from "crypto-js";
import API from "@/request/ApiServer";
export default {
  data() {
    return {
      // 上传部分
      uploadInfoSlice: {
        actionChunk: API.apiUrl + "chunk", // 上传路径
      },
      //新功能
      container: { file: null },
      chunkSize: 10 * 1024 * 1024, // 文件块大小10M
      allChunksUploaded: false,
      uploadProcess: 0,


      // loading
      fullscreenLoading: false,

      // 展示的模块
      showtime: 0,
      //展示的面包屑
      showvread: null,

      //
      // 教研圈详情数据
      intoducedata: null,
      // 话题数据
      talkdata: null,
      // 公告数据
      noticedata: null,
      // 成员数据
      memberdata: null,

      // ---
      // 是否有话题
      showlist: false,
      // 是否有公告
      shownotice: false,
      // 是否有成员
      showmember: false,

      //
      //   话题表单
      talkform: {
        content: null, //话题内容
        title: null, //话题标题
        fileList: null, //资源集合
        // type: null, //文件类型
      },
      fileList: [],
      //   文件类型
      options: [
        {
          value: 0,
          label: "图片",
        },
        {
          value: 1,
          label: "视频",
        },
        {
          value: 2,
          label: "文档",
        },
        {
          value: 3,
          label: "音频",
        },
        {
          value: 4,
          label: "压缩包",
        },
        {
          value: 5,
          label: "其他",
        },
      ],
      // 点赞
      collectionFlag: false, //收藏
      praiseFlag: false, //点赞
      //分页
      curpage: 1,
      pagesize: 10,
      total: null,

      //
      showone: null,
      showp: null,
      shown: null,
      arr: [],
      currentShow: false,
      currentmsg: null,
      showFileList: true,
    };
  },
  async created() {
    console.log(this.$route.query, "路有数据1111111119");
    console.log(sessionStorage.getItem("currentUserName"), "747474");
    //
    this.showone = this.$route.query.circlesite;
    this.showp = window.sessionStorage.getItem("defaultindex");
    this.shown = window.sessionStorage.getItem("defaultname");
    //
    if (this.$route.query.site !== undefined) {
      this.cutshow(this.$route.query.site, this.$route.query.title);
    }
    //
    await this.queryCircleDetailById(this.$route.query.introId);
    await this.queryMessageStatus(this.$route.query.introId);
    await this.queryCircleTalk(this.$route.query.introId);
    await this.queryCircleMember(this.$route.query.introId);
    await this.queryCircleNotic(this.$route.query.introId);

    if (
      sessionStorage.getItem("currentUserName") !=
      this.intoducedata.createUserName
    ) {
      this.currentShow = false;
    } else {
      this.currentShow = true;
    }
  },
  methods: {
    editFileList(data) {
      this.showFileList = data;
    },
    // 分片预请求
    async handleUploadPre(file) {
      this.currentmsg = "";
      const form = new FormData();
      form.append("fileSource", "APPLICATION");
      form.append("originFileName", file.name);
      let res = "";
      try {
        await Axios.post(API.apiUrl + "prepare", form).then((res) => {
          this.currentmsg = res.data.msg;
        });
      } catch (error) {
        throw new Error(error);
      }
      // 多文件
      const item = {
        uid: file.uid,
        file: file.raw,
        prepareId: res.msg,
      };
      this.progressFileList.push(item);
    },
    // 构造分片参数
    genFormData(chunks, uid) {
      const prepareId = this.getCurrentPrepareId(uid);
      return chunks.map((chunk) => {
        const form = new FormData();
        form.append("chunk", chunk.file);
        form.append("uploadId", this.currentmsg);
        form.append("partNumber", chunk.index);
        return form;
      });
    },
    // 合并请求
    async handleUploadMerge(file, uid) {
      // const prepareId = this.getCurrentPrepareId(uid);
      const form = new FormData();
      form.append("uploadId", this.currentmsg);
      form.append("filename", file.name);
      try {
        await Axios.post(API.apiUrl + "merge", form).then((res) => {
          console.log(res.data, "9999===");
          if (res.data.code == 0) {
            this.arr.push(res.data.data);
            this.talkform.fileList.push(res.data.data.id);
            Message.success("文件上传成功");
          } else {
            Message.error("文件上传失败,请重试");
          }
        });
      } catch (error) {
        return error;
      }
    }, // 判断当前处理prepareId
    getCurrentPrepareId(uid) {
      console.log(uid, "uiduid判断当前处理prepareId");
      for (const item of this.progressFileList) {
        if (item.uid === uid) {
          return item.prepareId;
        }
      }
    },
    async handleSuccess(res, file, fileList) {
      console.log("res@, file@, fileList@", res, file, fileList);
    },
    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.progressFileList = [];
      }
      let arr1 = [];
      this.arr.forEach((item) => {
        fileList.forEach((ele) => {
          if (item.resourceName == ele.name) {
            arr1.push(item.id);
          }
        });
      });
      this.talkform.fileList = arr1;
    },
    // 取消上传
    handleCancel(file, fileList) {
      console.log("取消file, 取消fileList", file, fileList);
    },

    //新功能
    handleFileChange(e) {
      const files = e.target.files;
      if (!files || files.length === 0) return;
      this.container.file = files[0];
      // 将上传完成状态置否
      this.allChunksUploaded = false;
      this.handleUpload();
    },
    makeChunks(file) {
      const fileChunks = {
        file,
        chunks: [],
      };
      if (file.size < this.chunkSize) {
        fileChunks.chunks.push({ start: 0, end: file.size });
        return fileChunks;
      }
      let chunksLength;
      if (file.size % this.chunkSize === 0) {
        chunksLength = Math.floor(file.size / this.chunkSize);
      } else {
        chunksLength = Math.floor(file.size / this.chunkSize) + 1;
      }
      let leftSize = file.size;
      while (leftSize > 0) {
        const start = fileChunks.chunks.length * this.chunkSize;
        const end =
          start + this.chunkSize >= file.size
            ? file.size
            : start + this.chunkSize;
        leftSize -= end - start;
        fileChunks.chunks.push({ start, end });
      }
      return fileChunks;
    },
    handleUpload(e) {
      console.log(`file: ${JSON.stringify(this.container.file)}`);
      const fileChunks = this.makeChunks(this.container.file);
      const allChunksUploadStatus = _.fill(
        new Array(fileChunks.chunks.length),
        false
      );
      // 重置上传进度的状态
      this.allChunksUploaded = false;
      this.uploadProcess = 0;
      // 计算上传进度
      const calUploadProcess = () => {
        let uploadedCount = 0;
        _.each(allChunksUploadStatus, (item) => {
          if (item) uploadedCount++;
        });
        return ((uploadedCount * 100) / allChunksUploadStatus.length).toFixed(
          0
        );
      };
      // 生成uid，传递给后端，后端根据uid对子文件块进行合并
      // const fileUid = randomId();
      const fileUid = CryptoJS.MD5(fileChunks.file.name).toString();
      console.log(fileChunks.chunks);
      fileChunks.chunks.forEach((chunk, index) => {
        const formData = new FormData();
        formData.append("index", index + "");
        formData.append("chunk", fileChunks.file.slice(chunk.start, chunk.end));
        formData.append("name", fileChunks.file.name);
        formData.append("chunksLength", fileChunks.chunks.length + "");
        formData.append("uid", fileUid);
        Axios.post(API.apiUrl + "fileUpload", formData)
          .catch((reason) => console.error(`error: ${JSON.stringify(reason)}`))
          .then((res) => {
            if (typeof res === "object") {
              const data = res.data;
              console.log(data);
              if (data.status === 200) {
                allChunksUploadStatus[index] = true;
                // 更新上传百分比
                this.uploadProcess = calUploadProcess();
                console.log("11111111111" + this.uploadProcess);
                // 更新上传状态
                if (fileChunks.chunks.length === 1) {
                  console.log("11111111111" + data.data.id);
                  this.talkform.fileList.push(data.data.id);
                } else {
                  if (this.uploadProcess === 100) {
                    this.allChunksUploaded = true;
                    console.log("11111111111" + data.data.id);
                    this.talkform.fileList.push(data.data.id);
                  }
                }
              }
            }
          });
      });
    },

    // 获取教研圈的详情数据
    async queryCircleDetailById(id) {
      let form = new FormData();
      form.append("circleId", id);
      await this.$Http.queryCircleDetailById(form).then((res) => {
        console.log(res, "查询单个圈子");
        if (res.status == 200) {
          if (res.data == null) {
            this.intoducedata = res.data;
          } else {
            let aData = JSON.parse(JSON.stringify(res.data));
            aData.periodList = aData.periodList.join("/");
            aData.gradeList = aData.gradeList.join("/");
            aData.subjectList = aData.subjectList.join("/");
            this.intoducedata = aData;
          }
        }
      });

      return;
      const { data, status } = await this.$Http.queryCircleDetailById({
        circleId: id,
        sortId: 1, //排序id 1-上传时间 2-圈子人数 3-收藏数量 4-点赞数量
        sortType: 2, //排序类型（1-正序，2-倒序）
        pageNo: 1, //页码
        pageSize: 10, //每页数量
      });
      if (status == 200) {
        // console.log(data, status, "查询单个圈子");
        let aData = JSON.parse(JSON.stringify(data.list[0]));
        aData.periodList = aData.periodList.join("/");
        aData.gradeList = aData.gradeList.join("/");
        aData.subjectList = aData.subjectList.join("/");
        this.intoducedata = aData;
        // this.intoducedata = data.list[0];
      }
    },

    // -
    // 查询话题
    async queryCircleTalk(id) {
      const { data, status } = await this.$Http.queryCircleTalk({
        pageNo: this.curpage,
        pageSize: this.pagesize,
        researchId: id,
      });
      if (status == 200) {
        // console.log(data, status, "查询话题");
        if (data == null) {
          this.showlist = true;
          this.talkdata = data;
          this.total = 0;
        } else {
          this.showlist = false;
          this.talkdata = data.list;
          this.total = data.total;
        }
      }
    },
    //查询公告
    async queryCircleNotic(id) {
      const { data, status } = await this.$Http.queryCircleNotic({
        pageNo: 1,
        pageSize: 5,
        researchId: id,
      });
      if (status == 200) {
        // console.log(data, status, "查询公告");
        if (data == null) {
          this.noticedata = data;
        } else {
          this.noticedata = data.list;
        }
      }
    },
    //切换地址
    gotopath(id) {
      this.$router.push({
        path: "/circlenotice",
        query: {
          id: id,
        },
      });
    },
    //
    // --查询成员
    async queryCircleMember(id) {
      let from = new FormData();
      from.append("circleId", id);
      const { data, status } = await this.$Http.queryCircleMember(from);
      if (status == 200) {
        // console.log(data, status, "获取成员信息");
        this.memberdata = data;
      }
    },
    // ---
    // 发布话题
    async saveCircleTalk() {
      if (this.talkform.title == null) {
        this.$message.warning("请输入话题标题");
        return;
      }
      if (this.talkform.content == null) {
        this.$message.warning("请输入话题内容");
        return;
      }
      // if (this.fileList.length == 0) {
      //   this.$message.warning("请选择附件");
      //   return;
      // }
      // else {
      // if (this.talkform.type == null) {
      //   this.$message.warning("请选择附件类型");
      //   return;
      // } else {
      // this.fullscreenLoading = true;

      if (this.fileList.length !== 0) {
        var loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }
      await this.$Http
        .saveCircleTalk({
          ...this.talkform,
          researchId: this.$route.query.introId - 0, //圈子Id
        })
        .then(({ data, status }) => {
          if (status == 200) {
            // console.log(data, status, "发布话题");
            this.queryCircleTalk(this.$route.query.introId);
            this.talkform.content = null; //话题内容
            this.talkform.title = null; //话题标题
            this.talkform.fileList = null; //资源集合
            // this.talkform.type = null; //文件类型
            this.fileList = [];
            this.arr = [];
            this.progressFileList = [];
            this.showFileList = false;
            // loading.close();
            // this.fullscreenLoading = false;
          } else {
            // loading.close();
            // this.fullscreenLoading = false;
          }
        });
    },
    // 上传附件
    submitUpload() {
      this.$refs.upload.submit();
    },
    //文件状态改变时的钩子
    handleChange(file, fileList) {
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // console.log(file, fileList, "文件状态改变时的钩子");
      let form = new FormData();
      form.append("file", file.raw);
      // form.append("fileType", this.talkform.type);
      this.$Http.uploadfile(form).then((res) => {
        // console.log(res, "上传文件1");
        if (res.status == 200) {
          loading.close();
          this.arr.push(res.data.id);
          fileList.forEach((item) => {
            if (item.name == file.name) {
              item.id = res.data.id;
            }
          });
        }
      });
      this.talkform.fileList = this.arr;
      this.fileList = fileList;
    },
    //移除文件
    handleBeforeRemove(file, fileList) {
      return this.$confirm("是否确认移除" + file.name, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log(file, fileList, "清除文件");
          this.$message({
            type: "success",
            message: "删除成功",
          });
          let arr1 = [];
          let arr2 = [];
          fileList.forEach((item) => {
            if (item.uid != file.uid) {
              arr1.push(item);
              arr2.push(item.id);
            }
          });
          this.talkform.fileList = arr2;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          reject(false);
        });
    },

    // ---
    // 获取点赞和收藏的情况
    async queryMessageStatus(id) {
      const { data, status } = await this.$Http.queryMessageStatus({
        serviceId: id,
        type: 3, //1-普通课程/资料 2-系列课程 3-教研圈 4-活动 5-广场
      });
      if (status == 200) {
        console.log(data, status, "点赞和收藏");
        this.collectionFlag = data.collectionFlag;
        this.praiseFlag = data.praiseFlag;
      }
    },
    // 点赞
    async messagePraise() {
      let id = this.$route.query.introId;
      const { data, status } = await this.$Http.messagePraise({
        serviceId: id,
        type: 3,
      });
      if (status == 200) {
        // console.log(data, status, "点赞");
        this.queryMessageStatus(id);
        this.queryCircleDetailById(id);
      }
    },
    // 取消点赞
    async deletePraise() {
      let id = this.$route.query.introId;
      const { data, status } = await this.$Http.deletePraise({
        serviceId: id,
        type: 3,
      });
      if (status == 200) {
        // console.log(data, status, "取消点赞");
        this.queryMessageStatus(id);
        this.queryCircleDetailById(id);
      }
    },
    // 收藏
    async messageCollection() {
      let id = this.$route.query.introId;
      const { data, status } = await this.$Http.messageCollection({
        serviceId: id,
        type: 3,
      });
      if (status == 200) {
        // console.log(data, status, "收藏");
        this.queryMessageStatus(id);
        this.queryCircleDetailById(id);
      }
    },
    // 取消收藏
    async messageDeleteCollection() {
      let id = this.$route.query.introId;
      const { data, status } = await this.$Http.messageDeleteCollection({
        serviceId: id,
        type: 3,
      });
      if (status == 200) {
        // console.log(data, status, "取消收藏");
        this.queryMessageStatus(id);
        this.queryCircleDetailById(id);
      }
    },

    // ---
    // 申请加入圈子
    async enterCircle() {
      let from = new FormData();
      from.append("circleId", this.$route.query.introId);
      this.$Http.enterCircle(from).then((res) => {
        console.log(res, "申请加入圈子");
        if (res.status == 200) {
          this.queryCircleDetailById(this.$route.query.introId);
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },

    // --
    //查看更多
    cutshow(num, title) {
      // console.log("跳转详情");

      this.queryCircleDetailById(this.$route.query.introId);
      this.queryMessageStatus(this.$route.query.introId);
      this.queryCircleTalk(this.$route.query.introId);
      this.queryCircleMember(this.$route.query.introId);
      this.queryCircleNotic(this.$route.query.introId);

      this.showtime = num;
      this.showvread = title;
    },
    cutshows(data) {
      this.showtime = data;
      this.queryCircleTalk(this.$route.query.introId);
    },
    // --
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCircleTalk(this.$route.query.introId);
    },
  },
  components: {
    notice,
    notices,
    UploadSlice,
  },
};
</script>
<style lang="scss" scoped>
.circleintroduce {
  // width: 1920px;
  // height: 2397px;
  // background: #f3f4f7;
  .circleintroduce_center {
    width: 1200px;
    margin: 0 auto;
    nav {
      padding: 15px 0;
    }
    header {
      // width: 1200px;
      // height: 365px;
      padding: 20px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      .h_left {
        img {
          width: 500px;
          height: 300px;
        }
        .h_icon {
          display: flex;
          justify-content: flex-start;
          .h_p_like {
            .like {
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blanklike.png");
            }
            .likes {
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidlike.png");
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          .h_p_star {
            margin-left: 40px;
            .star {
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blankstar.png");
            }
            .stars {
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidestar.png");
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
        }
      }
      .h_right {
        width: 720px;
        height: 280px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .r_title {
          max-width: 720px;
          //   height: 29px;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          //   line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        p {
          // width: 80px;
          // height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 19px;
        }
        .el-button {
          width: 160px;
        }
      }
    }
    main {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      .main_left {
        width: 780px;

        .m_title {
          // width: 144px;
          padding: 16px 20px;
          // height: 50px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #141516;
          // line-height: 50px;
          border-bottom: 1px solid #d7dce4;
          .m_t_span {
            max-width: 660px;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .m_list {
          min-height: 860px;
          .m_listli {
            padding: 20px 0px;
            margin: 0px 20px;
            border-bottom: 1px solid #d7dce4;
            .li_number {
              display: flex;
              justify-content: flex-start;
              .li_count {
                width: 40px;
                height: 24px;
                text-align: center;
                // width: 9px;
                // height: 17px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #8d9199;
                line-height: 20px;
                background: url("../../../assets/icon/replynum.png") no-repeat;
              }
              .li_title {
                margin-left: 20px;
                width: 380px;
                // height: 18px;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #141516;
                line-height: 24px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                cursor: pointer;
              }
              .li_user {
                .createuser {
                  width: 13px;
                  height: 13px;
                  display: inline-block;
                  background: url("../../../assets/icon/touxiang.png") no-repeat;
                  vertical-align: bottom;
                }
                span {
                  // width: 36px;
                  // height: 16px;
                  font-size: 12px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #8d9199;
                  line-height: 14px;
                  vertical-align: bottom;
                  margin-left: 3px;
                }
              }
            }
            .li_reply {
              display: flex;
              justify-content: flex-start;
              .lir_title {
                margin-left: 60px;
                width: 380px;
                // height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #5d6066;
                line-height: 21px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              .lir_user {
                .createusers {
                  width: 13px;
                  height: 13px;
                  display: inline-block;
                  background: url("../../../assets/icon/touxiangs.png")
                    no-repeat;
                  vertical-align: bottom;
                }
                span {
                  // width: 36px;
                  // height: 16px;
                  font-size: 12px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #8d9199;
                  line-height: 14px;
                  vertical-align: bottom;
                  margin-left: 3px;
                }
              }
              .lir_tiem {
                // width: 102px;
                height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #8d9199;
                // line-height: 14px;

                span {
                  // width: 36px;
                  // height: 16px;
                  font-size: 12px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #8d9199;
                  line-height: 28px;
                  vertical-align: bottom;
                  margin-left: 10px;
                }
              }
            }
          }
        }
        .el-pagination {
          text-align: center;
          padding: 20px 0;
        }
      }
      .main_right {
        width: 390px;
        // height: 200px;

        .r_introduce {
          background: #ffffff;
          .r_i_title {
            padding: 15px 20px;
            // width: 80px;
            // height: 21px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            border-bottom: 1px solid #d7dce4;
            // line-height: 19px;
          }
          .r_i_content {
            margin: 15px 20px;
            // width: 300px;
            // height: 90px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            // line-height: 16px;
            // letter-spacing: 1px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;

            // width: 200px;
            // word-break: break-all;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // /** 对象作为伸缩盒子模型显示 **/
            // -webkit-box-orient: vertical;
            // /** 设置或检索伸缩盒对象的子元素的排列方式 **/
            // -webkit-line-clamp: 2;
            // /** 显示的行数 **/
            // overflow: hidden;
            // /** 隐藏超出的内容 **/
            // word-wrap: break-word;
            // /*英文强制换行*/
          }
        }
        .r_notice {
          margin-top: 20px;
          background: #ffffff;
          .r_n_title {
            // padding: 15px 20px;
            .r_n_up {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #d7dce4;
              padding: 15px 20px;
              .r_n_up_title {
                // width: 80px;
                // height: 21px;
                font-size: 16px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #141516;
                // line-height: 19px;
              }
              .r_n_up_more {
                // width: 48px;
                // height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #5d6066;
                // line-height: 14px;
                cursor: pointer;
              }
              .r_n_up_more:hover {
                color: blue;
              }
            }
            .r_n_down {
              padding: 10px 40px 20px;
              ul {
                li {
                  margin-top: 5px;
                  // display: flex;
                  // justify-content: space-between;

                  .item_sapn {
                    width: 150px;
                    line-height: 13px;
                    // height: 19px;
                    font-size: 14px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #141516;
                    // line-height: 16px;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    cursor: pointer;
                  }
                  .items_span {
                    width: 150px;
                    display: inline-block;
                    line-height: 13px;
                    // width: 78px;
                    // height: 16px;
                    font-size: 14px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #8d9199;
                    // line-height: 16px;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
        .r_member {
          margin-top: 20px;
          background-color: #fff;
          .r_m_title {
            padding: 15px 20px;
            border-bottom: 1px solid #d7dce4;
          }
          .r_m_createuser {
            padding: 15px 20px;
            .r_m_logo {
              i {
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url("../../../assets/icon/creater.png") no-repeat;
              }
              span {
                display: inline-block;
                margin-left: 5px;
                // width: 42px;
                // height: 18px;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #141516;
                // line-height: 16px;
                vertical-align: top;
              }
            }
            .user_mmember {
              margin-top: 15px;
              display: inline-block;
              .member_flex {
                display: flex;
                justify-content: center;
              }
              p {
                min-width: 60px;
                margin-top: 5px;
                text-align: center;
                // width: 36px;
                // height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #5d6066;
                // line-height: 14px;
              }
            }
          }

          .r_m_createmember {
            padding: 15px 20px;
            .r_m_logo {
              i {
                display: inline-block;
                width: 14px;
                height: 13px;
                background: url("../../../assets/icon/peopleuser.png") no-repeat;
              }
              span {
                display: inline-block;
                margin-left: 5px;
                // width: 42px;
                // height: 18px;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #141516;
                // line-height: 16px;
                vertical-align: top;
              }
            }
            .r_m_oop {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
            }
            .user_mmember {
              margin-top: 15px;
              display: inline-block;
              .member_flex {
                display: flex;
                justify-content: center;
              }
              p {
                // min-width: 60px;
                width: 81px;
                margin-top: 5px;
                text-align: center;
                // width: 36px;
                // height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #5d6066;
                // line-height: 14px;
                word-wrap: break-word;
              }
            }
          }
        }
      }
    }
    footer {
      margin-top: 25px;
      background-color: #fff;
      padding: 20px;
      .f_title {
        i {
          display: inline-block;
          width: 18px;
          height: 18px;
          vertical-align: middle;
          background: url("../../../assets/icon/newtalk.png") no-repeat;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          // width: 80px;
          // height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #141516;
          // line-height: 19px;
        }
      }
      .f_i_title {
        margin: 10px 0;
      }
    }
  }
}
</style>
