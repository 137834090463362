<template>
  <div class="upclass">
    <!-- <h1>课程上传</h1> -->
    <div class="upclass_center">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="card">
        <el-tab-pane label="基本信息" name="first" :disabled="true">
          <el-form
            ref="upformRef"
            :rules="upRules"
            :model="infoForm"
            label-width="150px"
          >
            <!--  -->
            <el-form-item label="课程标题" prop="title">
              <el-input
                v-model="infoForm.title"
                placeholder="请输入课程名称"
                clearable
              ></el-input>
            </el-form-item>
            <!--  -->
            <el-form-item label="学段" prop="period">
              <el-select
                v-model="infoForm.period"
                multiple
                placeholder="请选择学段"
                @change="selectAlls"
                clearable
              >
                <!-- style="position: absolute;right:-4px;top:0px; color: #409EFF; z-index: 999;" -->
                <el-option
                  v-for="item in optionPeriod"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
                <el-option label="全选" value="all"></el-option>
              </el-select>
            </el-form-item>
            <!--  -->
            <el-form-item label="年级" prop="grade">
              <el-select
                v-model="infoForm.grade"
                multiple
                placeholder="请选择年级"
                @change="selectAll"
                ref="gradeInfoRef"
                @click.native="gradeinfo"
                clearable
              >
                <!-- collapse-tags -->
                <!-- style="position: absolute;right:-4px;top:0px; color: #409EFF; z-index: 999;" -->
                <el-option label="全选" value="all"></el-option>
                <el-option
                  v-for="item in optionGrade"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!--  -->
            <el-form-item label="学科类型" prop="style">
              <el-select
                v-model="infoForm.style"
                placeholder="请选择学科类型"
                @change="selectStyle"
                clearable
              >
                <el-option
                  v-for="item in optionStyle"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!--  -->
            <el-form-item label="学科" prop="subject" clearable>
              <el-select
                v-model="infoForm.subject"
                placeholder="请选择学科"
                collapse-tags
                clearable
                ref="subjectInfoRef"
                @click.native="subjectinfo"
              >
                <!-- style="position: absolute;right:-4px;top:0px; color: #409EFF; z-index: 999;" -->
                <!-- <el-option label="全选" value="all"></el-option> -->
                <el-option
                  v-for="item in optionSbject"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!--  -->
            <!--  -->
            <el-form-item label="课程简介">
              <el-input
                type="textarea"
                v-model="infoForm.introduction"
                clearable
              ></el-input>
            </el-form-item>
            <!--  -->
            <classcover
              @pirctureUrl="editpircture"
              :previewImg="previewImg"
            ></classcover>
            <!--  -->
            <el-form-item>
              <el-button type="primary" @click="goback" size="mini">
                退出
              </el-button>
              <el-button type="primary" @click="nextstep" size="mini">
                下一步
              </el-button>
            </el-form-item>
          </el-form>
          <!-- <classcover @pirctureUrl="editpircture"></classcover> -->
        </el-tab-pane>
        <!--  -->
        <el-tab-pane
          label="建立课时"
          name="second"
          class="r_tab"
          :disabled="true"
        >
          <el-button type="primary" size="small" @click="createChaper">
            创建新章节
          </el-button>
          <el-button type="primary" size="mini" @click="meansUpload">
            课时资料上传
          </el-button>
          <div class="p_minul">
            <div class="p_ulbtn" v-for="item in chaperArray" :key="item.id">
              <div class="p_ul">
                <div class="p_title" @click="viewHour(item.id)">
                  <div class="p_i_icon">
                    <p>{{ item.catelageueName }}</p>
                    <!-- <div v-if="item.children!==undefined"> -->
                    <i
                      class="upicon"
                      v-if="showchilren && item.id == showId"
                      @click="cutchildren(false, item.id)"
                    ></i>
                    <i
                      class="downicon"
                      v-else
                      @click="cutchildren(true, item.id)"
                    ></i>
                    <!-- </div> -->
                  </div>
                  <div class="p_btns">
                    <p @click="creatHour(item.id)">
                      <i class="createicon"></i>
                      <span>创建课时</span>
                    </p>
                    <p @click="editChaper(item)">
                      <i class="editicon"></i>
                      <span>编辑</span>
                    </p>
                    <!-- <el-button size="mini" type="primary" @click="delChaper(item.id)">删除</el-button> -->
                  </div>
                </div>
                <div class="p_btn">
                  <!-- <el-button size="mini" type="primary" @click="editChaper(item)">编辑</el-button> -->
                  <p @click="delChaper(item.id)">
                    <i class="delicons"></i>
                    <span>删除</span>
                  </p>
                  <!-- <el-button size="mini" type="primary" @click="delChaper(item.id)">删除</el-button> -->
                  <!-- <el-button size="mini" type="primary" @click="creatHour(item.id)">创建课时</el-button> -->
                </div>
              </div>
              <div v-if="showchilren && item.id == showId">
                <div
                  class="p_li"
                  v-for="items in item.children"
                  :key="items.id"
                >
                  <div class="p_lll">
                    <p class="p_titles">{{ items.catelageueName }}</p>
                    <div class="lll_icon">
                      <p @click="viewVideo(items.videoUrl[0].url)">
                        <i class="one_icon"></i>
                        <span>预览视频</span>
                      </p>
                      <p @click="viewMeans(items)">
                        <i class="two_icon"></i>
                        <span>查看课时资料</span>
                      </p>
                      <p @click="editHour(items)">
                        <i class="three_icon"></i>
                        <span>编辑</span>
                      </p>
                    </div>
                  </div>
                  <div class="p_d_btns">
                    <!-- <el-button size="mini" type="primary" @click="viewVideo(items.videoUrl[0].url)">查看视频</el-button> -->
                    <!-- <el-button size="mini" type="primary">编辑</el-button> -->
                    <!-- <el-button size="mini" type="primary">查看资料</el-button> -->
                    <p @click="delHour(item.id, items.id)">
                      <i class="deicon"></i>
                      <span>删除</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p_footer_btns">
            <el-button type="primary" size="mini" @click="lastStep">
              上一步
            </el-button>
            <el-button type="primary" size="mini" @click="createFinish">
              完成
            </el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!--  -->
      <!-- 创建新章节 -->
      <el-dialog
        title="新建章节信息"
        :visible.sync="newChaper"
        class="chaper_menu"
        width="400px"
        top="15%"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <el-form :model="chaperFrom" class="chaper_center" ref="chaperRef">
          <!-- <el-form-item label="章节序号：" label-width="100px" prop="cNumber">
          第 <el-input v-model="chaperFrom.cNumber" autocomplete="off" class="first_input"size="mini"></el-input> 章 </el-form-item>-->
          <el-form-item
            label="章节标题："
            label-width="100px"
            class="chaper_center"
            prop="catalogueName"
          >
            <el-input
              v-model="chaperFrom.catalogueName"
              autocomplete="off"
              class="second_input"
              size="mini"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveChaper(true)" size="small">
            保存
          </el-button>
          <el-button type="primary" @click="saveChaper(false)" size="small">
            保存并继续上传
          </el-button>
          <el-button @click="cencleChaper" size="small">取消</el-button>
        </div>
      </el-dialog>
      <!-- 编辑章节 -->
      <el-dialog
        title="编辑章节信息"
        :visible.sync="editinfoChaper"
        class="chaper_menu"
        width="400px"
        top="15%"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <el-form
          :model="editChaperFrom"
          class="chaper_center"
          ref="editChaperRef"
        >
          <!-- <el-form-item label="章节序号：" label-width="100px" prop="cNumber">
          第 <el-input v-model="editChaperFrom.cNumber" autocomplete="off" class="first_input"size="mini"></el-input> 章 </el-form-item>-->
          <el-form-item
            label="章节标题："
            label-width="100px"
            class="chaper_center"
            prop="catalogueName"
          >
            <el-input
              v-model="editChaperFrom.catelogueName"
              autocomplete="off"
              class="second_input"
              size="mini"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editSure" size="small">
            保存
          </el-button>
          <el-button @click="editCancle" size="small">取消</el-button>
        </div>
      </el-dialog>
      <!-- 创建/编辑 课时 -->
      <el-dialog
        :title="hourTitle"
        :visible.sync="newClassHour"
        class="chour_menu"
        width="450px"
        top="15%"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
        v-loading="tloading"
        element-loading-text="正在上传中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form :model="hourform" class="chour_center" ref="chourRef">
          <!-- <el-form-item label="课时排序" label-width="100px" prop="sort">
            <el-input v-model="hourform.sort" autocomplete="off"></el-input>
          </el-form-item>-->
          <el-form-item
            label="课时名称"
            label-width="100px"
            prop="sort"
            size="mini"
          >
            <el-input
              v-model="hourform.catalogueName"
              autocomplete="off"
              class="second_input"
            ></el-input>
          </el-form-item>
          <!-- :limit="1" -->
          <!--          <el-form-item label="上传视频" label-width="100px">
            <el-upload
              class="upload-demo"
              action="#"
              accept=".mp4"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="hourfileChange"
            >
              <el-button size="mini" type="primary">上传视频</el-button>
              <div slot="tip" class="el-upload__tip" style="line-height:10px">只允许上传mp4格式的视频</div>
            </el-upload>
          </el-form-item>-->
          <el-form-item label="上传视频" label-width="100px">
            <UploadSlice
              :action="uploadInfoSliceAA.actionChunk"
              :headers="uploadInfoSliceAA.headers"
              :limit="uploadInfoSliceAA.limit"
              :accept="uploadInfoSliceAA.accept"
              :show-file-list="false"
              multiple
              cancelable
              :on-success="handleSuccessAA"
              :on-cancel="handleCancelAA"
              :on-upload-pre="handleUploadPreAA"
              :on-upload-merge="handleUploadMergeAA"
              :on-form-data="genFormDataAA"
              :showFileList="showFileList"
              @editFileList="editFileList"
              :showIniInitFileList="fileList"
            />
            <!-- <h1>
              <input type="file" @change="handleFileChange" accept=".mp4">
                               <button @click="handleUpload">上传</button>
            </h1>
            <span>{{
                allChunksUploaded ? "完成" : "上传进度：" + uploadProcess + "%"
              }}</span>
            <div style="line-height:10px">只允许上传mp4格式的视频</div> -->
          </el-form-item>
          <el-form-item label="视频预览" label-width="100px" prop="sort">
            <div
              class="showView"
              style="
                width: 270px;
                height: 180px;
                background: #fff;
                border: 1px solid #d2d2d2;
              "
              ref="viedoref"
            >
              <video
                :src="videoUrl"
                class="avatar"
                controls="controls"
                v-if="this.videoUrl !== null"
                width="270"
                height="180"
              ></video>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            size="mini"
            type="primary"
            @click="saveHour(true)"
            v-if="this.hourTitle == '创建课时'"
          >
            保存
          </el-button>
          <el-button size="mini" type="primary" @click="editsaveHour" v-else>
            保存
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="saveHour(false)"
            v-if="this.hourTitle == '创建课时'"
          >
            保存并继续创建课时
          </el-button>
          <el-button size="mini" @click="cancelHour">取消</el-button>
        </div>
      </el-dialog>
      <!-- 添加课时资料 -->
      <el-dialog
        title="添加课时资料"
        :visible.sync="addMeans"
        class="cmeans_menu"
        width="600px"
        top="15%"
        :showClose="false"
        :closeOnPressEscape="false"
        :closeOnClickModal="false"
        v-loading="meansloading"
        element-loading-text="正在上传中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
      >
        <el-form :model="meansForm" class="means_center" ref="meansRef">
          <el-form-item label="选择章节" label-width="100px" prop="mChaper">
            <el-select
              v-model="meansForm.mChaper"
              placeholder="请选择"
              @change="selectChaper"
            >
              <el-option
                v-for="item in meansArray"
                :key="item.id"
                :label="item.catelageueName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择课时" label-width="100px" prop="mHour">
            <el-select
              v-model="meansForm.mHour"
              multiple
              placeholder="请选择"
              ref="hourSelectRef"
              @click.native="meansInfo"
            >
              <el-option
                v-for="item in meansHour"
                :key="item.id"
                :label="item.catelageueName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="上传资料格式" label-width="100px" prop="mResources">
            <el-select v-model="meansForm.mResources" placeholder="请选择">
              <el-option v-for="item in meansResources" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>-->
          <!--          <el-form-item label label-width="100px">
            <el-upload
              class="upload-demo"
              ref="uploadRef"
              name="files"
              action
              :file-list="fileList"
              :auto-upload="false"
              :on-remove="handleRemove"
              :on-change="handleChange"
              :before-remove="handleBeforeRemove"
              multiple
              accept
            >
              <el-button slot="trigger" size="mini" type="primary">选取文件</el-button>
            </el-upload>
          </el-form-item>-->
          <el-form-item label="上传资源" label-width="100px">
            <!-- <h1>
              <input type="file" @change="handleFileChange1" />
              <button @click="handleUpload">上传</button>
            </h1>
            <span>{{
              allChunksUploaded1 ? "完成" : "上传进度：" + uploadProcess1 + "%"
            }}</span> -->
            <UploadSlice
              :action="uploadInfoSliceBB.actionChunk"
              :headers="uploadInfoSliceBB.headers"
              :limit="uploadInfoSliceBB.limit"
              :accept="uploadInfoSliceBB.accept"
              :show-file-list="true"
              multiple
              cancelable
              :on-success="handleSuccessBB"
              :on-cancel="handleCancelBB"
              :on-upload-pre="handleUploadPreBB"
              :on-upload-merge="handleUploadMergeBB"
              :on-form-data="genFormDataBB"
              :on-remove="handleRemoveBB"
              :showFileList="showFileList"
              @editFileList="editFileList"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="sureMeans">
            确认
          </el-button>
          <el-button size="mini" @click="cancleMeans">取消</el-button>
        </div>
      </el-dialog>
      <!-- width="600px" -->
      <!-- 查看课时资料 -->
      <el-dialog
        title="查看课时资料"
        :visible.sync="meansDialog"
        class="cmeans_dialog"
        top="15%"
        :closeOnClickModal="false"
      >
        <el-card class="box-card">
          <div
            v-for="(item, index) in meansData"
            :key="item.id"
            class="card_item"
          >
            <!-- <p>{{item}}</p> -->
            <p>{{ item.resouceName }}</p>
            <el-button size="mini" @click="delMeansData(item.resouceId, index)">
              <i class="delicon"></i>
              <span>删除</span>
            </el-button>
          </div>
        </el-card>
      </el-dialog>
      <!-- 查看视频 -->
      <el-dialog
        title="查看视频"
        :visible.sync="viewVideolog"
        class="videomeans"
        width="800px"
        top="15%"
        :closeOnClickModal="false"
      >
        <div class="videolog">
          <video
            :src="viewVideoUrl"
            controls="controls"
            width="600"
            height="450"
          ></video>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import UploadSlice from "@/components/UploadSlice.vue";
import { Message } from "element-ui";
import classcover from "./component/classcover.vue";
import Axios, { AxiosResponse } from "axios";
import _ from "lodash";
import CryptoJS from "crypto-js";
import API from "@/request/ApiServer";
// import { mapState } from "vuex";
export default {
  components: {
    classcover,
    UploadSlice,
  },
  data() {
    return {
      // 上传部分
      uploadInfoSliceAA: {
        actionChunk: API.apiUrl + "chunk", // 上传路径
        limit: 1,
        accept: ".mp4",
      },
      currentmsgAA: null,
      showFileList: true,
      uploadInfoSliceBB: {
        actionChunk: API.apiUrl + "chunk", // 上传路径
      },
      currentmsgBB: null,
      arrBB: [],
      //新功能
      container: { file: null },
      chunkSize: 10 * 1024 * 1024, // 文件块大小10M
      allChunksUploaded: false,
      uploadProcess: 0,

      container1: { file: null },
      chunkSize1: 10 * 1024 * 1024, // 文件块大小10M
      allChunksUploaded1: false,
      uploadProcess1: 0,


      //控制章节的箭头
      showchilren: 1,
      showId: null,
      //tabs
      activeName: "first",
      //下一步年级和学段匹配的的判断
      aVal: null,
      // 判断是否点击了下一步
      shownext: false,

      // -------基本信息
      // 基本信息表单数据
      infoForm: {
        title: null, //课程名称
        period: [], //学段
        grade: [], //年级
        style: null, //学科类型
        subject: null, //学科
        files: null, //图片文件---图片id
        introduction: null, //简介
      },
      // 基本信息表单规则
      upRules: {
        title: [{ required: true, message: "请输入课程标题", trigger: "blur" }],
        period: [{ required: true, message: "请选择学段", trigger: "change" }],
        grade: [{ required: true, message: "请选择年级", trigger: "change" }],
        style: [
          { required: true, message: "请选择学科类型", trigger: "change" },
        ],
        subject: [{ required: true, message: "请选择学科", trigger: "change" }],
      },
      // 学段
      optionPeriod: null,
      //年级数据
      optionGrade: null,
      //学科类型
      optionStyle: null,
      // 学科
      optionSbject: null,

      // 防止重复提交
      loading: false,

      // 图片预览
      previewImg: null,
      //-------上传章节
      courseId: null, //课程id
      newChaper: false, //新建章节弹框
      editinfoChaper: false, //编辑章节弹框
      // 章节弹框表单
      chaperFrom: {
        // cNumber: null,
        catalogueName: null,
      },
      // 修改章节
      editChaperFrom: {
        catelogueId: null,
        // cNumber: null,
        catelogueName: null,
      },
      //章节列表
      chaperArray: null,

      // 课时的章节id
      hourTitle: "创建课时",
      parentId: null,
      classHourId: null,
      videoUrl: null, //视频预览结果
      newClassHour: false, //课时弹框
      // 课时 表单
      hourform: {
        // sort: null, //课时排序
        catalogueName: null, //课时名称
        fileIdList: [],
      },
      // 课时列表
      // hourArray: null,

      // 课时资料
      addMeans: false, //弹框
      // 课时资料的表单
      meansForm: {
        mChaper: null,
        mHour: null,
        mResources: null,
      },
      // 章节信息
      meansArray: null,
      // 课时信息
      meansHour: null,
      // 上传文件类型
      // meansResources: null,

      //上传的文件列表
      fileList: [],
      // 课时资料的loading
      meansloading: false, //资料
      resourceNum: [],
      tloading: false, //创建-编辑视频

      // 课时资料弹框
      meansDialog: false,
      meansData: null,
      meansId: null,

      //查看视频
      viewVideolog: false,
      viewVideoUrl: null,
    };
  },
  created() {
    console.log(this.$route.query.id, "classRaw");
    this.courseId = this.$route.query.id;
    this.queryclassRaw();
    this.queryPeriod();
    this.querySubjectType();
    // this.createResourse();
  },
  methods: {
    editFileList(data) {
      this.showFileList = data;
    },
    // 分片预请求
    async handleUploadPreAA(file) {
      const form = new FormData();
      form.append("fileSource", "APPLICATION");
      form.append("originFileName", file.name);
      let res = "";
      try {
        await Axios.post(API.apiUrl + "prepare", form).then((res) => {
          this.currentmsgAA = res.data.msg;
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    // 构造分片参数
    genFormDataAA(chunks, uid) {
      return chunks.map((chunk) => {
        const form = new FormData();
        form.append("chunk", chunk.file);
        form.append("uploadId", this.currentmsgAA);
        form.append("partNumber", chunk.index);
        return form;
      });
    },
    // 合并请求
    async handleUploadMergeAA(file, uid) {
      this.hourform.fileIdList = []
      const form = new FormData();
      form.append("uploadId", this.currentmsgAA);
      form.append("filename", file.name);
      // return 建议使用, 用于handleSuccessAA获取数据
      try {
        await Axios.post(API.apiUrl + "merge", form).then((res) => {
          if (res.data.code == 0) {
            this.videoUrl = res.data.data.resourceUrl;
            this.hourform.fileIdList.push(res.data.data.id);
            Message.success("文件上传成功");
          } else {
            Message.error("文件上传失败,请重试");
          }
        });
      } catch (error) {
        return error;
      }
    },
    async handleSuccessAA(res, file, fileList) {
      console.log("res, file, fileList", res, file, fileList);
    },
    // 取消上传
    handleCancelAA(file, fileList) {
      console.log("file, fileList", file, fileList);
    },
    // ==============================================
    // 分片预请求
    async handleUploadPreBB(file) {
      const form = new FormData();
      form.append("fileSource", "APPLICATION");
      form.append("originFileName", file.name);
      let res = "";
      try {
        await Axios.post(API.apiUrl + "prepare", form).then((res) => {
          this.currentmsgBB = res.data.msg;
        });
      } catch (error) {
        throw new Error(error);
      }
      // 多文件
      const item = {
        uid: file.uid,
        file: file.raw,
        prepareId: res.msg,
      };
      this.progressFileList.push(item);
    },
    // 构造分片参数
    genFormDataBB(chunks, uid) {
      return chunks.map((chunk) => {
        const form = new FormData();
        form.append("chunk", chunk.file);
        form.append("uploadId", this.currentmsgBB);
        form.append("partNumber", chunk.index);
        return form;
      });
    },
    // 合并请求
    async handleUploadMergeBB(file, uid) {
      const form = new FormData();
      form.append("uploadId", this.currentmsgBB);
      form.append("filename", file.name);
      // return 建议使用, 用于handleSuccessAA获取数据
      try {
        await Axios.post(API.apiUrl + "merge", form).then((res) => {
          if (res.data.code == 0) {
            this.arrBB.push(res.data.data);
            this.resourceNum.push(res.data.data.id);
            Message.success("文件上传成功");
          } else {
            Message.error("文件上传失败,请重试");
          }
        });
      } catch (error) {
        return error;
      }
    },
    async handleSuccessBB(res, file, fileList) {
      console.log("res, file, fileList", res, file, fileList);
    },
    handleRemoveBB(file, fileList) {
      if (!fileList.length) {
        this.progressFileList = [];
      }
      let arr1 = [];
      this.arrBB.forEach((item) => {
        fileList.forEach((ele) => {
          if (item.resourceName == ele.name) {
            arr1.push(item.id);
          }
        });
      });
      this.resourceNum = arr1;
    },
    // 取消上传
    handleCancelBB(file, fileList) {
      console.log("file, fileList", file, fileList);
    },
    //新功能
    handleFileChange(e) {
      const files = e.target.files;
      if (!files || files.length === 0) return;
      this.container.file = files[0];
      // 将上传完成状态置否
      this.allChunksUploaded = false;
      this.handleUpload();
    },
    makeChunks(file) {
      const fileChunks = {
        file,
        chunks: [],
      };
      if (file.size < this.chunkSize) {
        fileChunks.chunks.push({ start: 0, end: file.size });
        return fileChunks;
      }
      let chunksLength;
      if (file.size % this.chunkSize === 0) {
        chunksLength = Math.floor(file.size / this.chunkSize);
      } else {
        chunksLength = Math.floor(file.size / this.chunkSize) + 1;
      }
      let leftSize = file.size;
      while (leftSize > 0) {
        const start = fileChunks.chunks.length * this.chunkSize;
        const end =
          start + this.chunkSize >= file.size
            ? file.size
            : start + this.chunkSize;
        leftSize -= end - start;
        fileChunks.chunks.push({ start, end });
      }
      return fileChunks;
    },
    handleUpload(e) {
      console.log(`file: ${JSON.stringify(this.container.file)}`);
      const fileChunks = this.makeChunks(this.container.file);
      const allChunksUploadStatus = _.fill(
        new Array(fileChunks.chunks.length),
        false
      );
      // 重置上传进度的状态
      this.allChunksUploaded = false;
      this.uploadProcess = 0;
      // 计算上传进度
      const calUploadProcess = () => {
        let uploadedCount = 0;
        _.each(allChunksUploadStatus, (item) => {
          if (item) uploadedCount++;
        });
        return ((uploadedCount * 100) / allChunksUploadStatus.length).toFixed(
          0
        );
      };
      // 生成uid，传递给后端，后端根据uid对子文件块进行合并
      // const fileUid = randomId();
      const fileUid = CryptoJS.MD5(fileChunks.file.name).toString();
      console.log(fileChunks.chunks);
      fileChunks.chunks.forEach((chunk, index) => {
        console.log("index" + index);
        const formData = new FormData();
        formData.append("index", index + "");
        formData.append("chunk", fileChunks.file.slice(chunk.start, chunk.end));
        formData.append("name", fileChunks.file.name);
        formData.append("chunksLength", fileChunks.chunks.length + "");
        formData.append("uid", fileUid);
        Axios.post(API.apiUrl + "fileUpload", formData)
          .catch((reason) => console.error(`error: ${JSON.stringify(reason)}`))
          .then((res) => {
            if (typeof res === "object") {
              const data = res.data;
              if (data.status === 200) {
                allChunksUploadStatus[index] = true;
                // 更新上传百分比
                this.uploadProcess = calUploadProcess();
                // 更新上传状态
                if (fileChunks.chunks.length === 1) {
                  this.hourform.fileIdList = [];
                  this.hourform.fileIdList.push(data.data.id);
                  this.videoUrl = data.data.resourceUrl;
                } else {
                  if (this.uploadProcess === 100) {
                    this.allChunksUploaded = true;
                    this.hourform.fileIdList = [];
                    this.hourform.fileIdList.push(data.data.id);
                    this.videoUrl = data.data.resourceUrl;
                  }
                }
              }
            }
          });
      });
    },

    handleFileChange1(e) {
      const files = e.target.files;
      if (!files || files.length === 0) return;
      this.container1.file = files[0];
      // 将上传完成状态置否
      this.allChunksUploaded1 = false;
      this.handleUpload1();
    },
    handleUpload1(e) {
      console.log(`file: ${JSON.stringify(this.container1.file)}`);
      const fileChunks = this.makeChunks(this.container1.file);
      const allChunksUploadStatus = _.fill(
        new Array(fileChunks.chunks.length),
        false
      );
      // 重置上传进度的状态
      this.allChunksUploaded1 = false;
      this.uploadProcess1 = 0;
      // 计算上传进度
      const calUploadProcess = () => {
        let uploadedCount = 0;
        _.each(allChunksUploadStatus, (item) => {
          if (item) uploadedCount++;
        });
        return ((uploadedCount * 100) / allChunksUploadStatus.length).toFixed(
          0
        );
      };
      // 生成uid，传递给后端，后端根据uid对子文件块进行合并
      // const fileUid = randomId();
      const fileUid = CryptoJS.MD5(fileChunks.file.name).toString();
      console.log(fileChunks.chunks);
      fileChunks.chunks.forEach((chunk, index) => {
        console.log("index" + index);
        const formData = new FormData();
        formData.append("index", index + "");
        formData.append("chunk", fileChunks.file.slice(chunk.start, chunk.end));
        formData.append("name", fileChunks.file.name);
        formData.append("chunksLength", fileChunks.chunks.length + "");
        formData.append("uid", fileUid);
        Axios.post(API.apiUrl + "fileUpload", formData)
          .catch((reason) => console.error(`error: ${JSON.stringify(reason)}`))
          .then((res) => {
            if (typeof res === "object") {
              const data = res.data;
              if (data.status === 200) {
                allChunksUploadStatus[index] = true;
                // 更新上传百分比
                this.uploadProcess1 = calUploadProcess();
                // 更新上传状态
                if (fileChunks.chunks.length === 1) {
                  this.resourceNum = [];
                  this.resourceNum.push(data.data.id);
                } else {
                  if (this.uploadProcess === 100) {
                    this.allChunksUploaded = true;
                    this.resourceNum = [];
                    this.resourceNum.push(data.data.id);
                  }
                }
              }
            }
          });
      });
    },

    //
    cutchildren(sty, id) {
      console.log(sty, id, "切换");
      this.showchilren = sty;
      this.showId = id;
    },
    //编辑的图片
    editpircture(data) {
      this.infoForm.files = data;
    },
    //-----tabs切换
    //点击事件
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 编辑课程的raw获取
    async queryclassRaw() {
      console.log(this.courseId, "this.courseId");
      if (this.$route.query.id == null) {
        return;
      } else {
        let idlist = [this.$route.query.id];
        const { data, status } = await this.$Http.queryCourselists({
          courseIdList: idlist,
          courseType: 1, //课程类型(1-课程，2-资料)
          sortId: 1, //排序Id
          sortType: 1, //排序类型
        });
        if (status == 200) {
          // console.log(data.list[0], status);
          console.log("编辑课程");
          this.dealClassRaw(data.list[0]);
        }
      }
    },
    // 数据处理
    async dealClassRaw(data) {
      console.log(data, "数据");
      this.infoForm.title = data.title;
      this.infoForm.introduction = data.conent;
      this.previewImg = data.pictureUrl;
      this.infoForm.files = data.pictureId;
      let aPeriod = [];
      let aGrade = [];
      let aStyle = null;
      let aSubject = null;
      // 字段
      for (let x = 0; x < data.periodName.length; x++) {
        for (let y = 0; y < this.optionPeriod.length; y++) {
          if (data.periodName[x] === this.optionPeriod[y].name) {
            aPeriod.push(this.optionPeriod[y].id);
          }
        }
      }
      this.queryGrade(aPeriod).then(() => {
        for (let x = 0; x < data.gradeName.length; x++) {
          for (let y = 0; y < this.optionGrade.length; y++) {
            if (data.gradeName[x] == this.optionGrade[y].name) {
              aGrade.push(this.optionGrade[y].id);
            }
          }
        }
        this.infoForm.period = aPeriod;
        this.infoForm.grade = aGrade;
      });
      // 学科subjectType
      for (let x = 0; x < data.subjectType.length; x++) {
        for (let y = 0; y < this.optionStyle.length; y++) {
          if (data.subjectType[x] == this.optionStyle[y].name) {
            aStyle = this.optionStyle[y].id;
          }
        }
      }
      this.querySubject(aStyle).then(() => {
        for (let x = 0; x < data.subjectName.length; x++) {
          for (let y = 0; y < this.optionSbject.length; y++) {
            if (data.subjectName[x] == this.optionSbject[y].name) {
              aSubject = this.optionSbject[y].id;
            }
          }
        }
        this.infoForm.style = aStyle;
        this.infoForm.subject = aSubject;
      });
    },
    //-----基本信息获取数据
    //学段
    async queryPeriod() {
      const { data, status } = await this.$Http.queryPeriod();
      if (status == 200) {
        this.optionPeriod = data;
        // console.log(data, status, "获取学段类型");
      }
    },
    //年级
    async queryGrade(id) {
      let idList = [];
      if (id == null || id == 0) {
        idList = [];
      } else {
        if (typeof id === "number") {
          idList.push(id);
        } else if (typeof id == "object") {
          idList = id;
        }
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: idList,
      });
      if (status == 200) {
        this.optionGrade = data;
        // console.log(data, status, "获取年级类型");
      }
    },
    //学科类型
    async querySubjectType() {
      const { data, status } = await this.$Http.querySubjectType();
      if (status == 200) {
        this.optionStyle = data;
        // console.log(data, status, "获取学科类型");
      }
    },
    // 学科
    async querySubject(id) {
      let idList = [];
      if (id == null || id == 0) {
        idList = [];
      } else {
        if (typeof id === "number") {
          idList.push(id);
        } else if (typeof id == "object") {
          idList = id;
        }
      }
      const { data, status } = await this.$Http.querySubject({
        idList: idList,
      });
      if (status == 200) {
        this.optionSbject = data;
        // console.log(data, status, "获取学科");
      }
    },
    // ---基本信息提交数据
    // 基本信息数据保存
    async saveBaseCourse() {
      let aData = this.infoForm;
      let aa = []; //存学科类型id
      let bb = []; //存学科id
      aa.push(aData.style);
      bb.push(aData.subject);
      const { data, status } = await this.$Http.saveBaseCourse({
        courseId: this.courseId, //课程id
        courseTitle: aData.title, //课程标题
        periodIdList: aData.period, //学段id集合
        gradeIdList: aData.grade, //年级id集合
        // subjectTypeIdList: aData.style, //	学科类型id
        // subjectIdList: aData.subject, //	学科id
        subjectTypeIdList: aa, //	学科类型id
        subjectIdList: bb, //	学科id
        content: aData.introduction, //简介
        pictureUrl: aData.files, //图片地址
      });
      if (status == 200) {
        this.activeName = "second";
        // console.log(data, status, "上传基本信息");
        this.courseId = data;
        this.viewChaper();
      }
    },
    // 下一步 --提交前的判断 --确认提交
    nextstep() {
      console.log(this.infoForm, "infoForm");
      this.$refs["upformRef"].validate((valid) => {
        if (!valid) return;
        this.judgeGrade();
        if (this.aVal) return;
        // if (this.infoForm.files == null)
        //   return this.$message.error("请上传封面");
        this.saveBaseCourse();
      });
    },
    //  判断学段和年级--提交前的判断
    judgeGrade() {
      let aData = this.infoForm.period;
      let bData = this.infoForm.grade;
      let cArray = [];
      aData.map((val) => {
        if (val == 1) {
          cArray.push(
            bData.some((val) => {
              return val == 1;
            })
          );
        } else if (val == 2) {
          cArray.push(
            bData.some((val) => {
              return val > 1 && val < 8;
            })
          );
        } else if (val == 3) {
          cArray.push(
            bData.some((val) => {
              return val > 7 && val < 11;
            })
          );
        } else if (val == 4) {
          cArray.push(
            bData.some((val) => {
              return val > 10 && val < 14;
            })
          );
        } else if (val == 5) {
          cArray.push(
            bData.some((val) => {
              return val > 13 && val < 17;
            })
          );
        }
      });
      const rData = cArray.every((item) => {
        return item == true;
      });
      if (!rData) {
        this.aVal = true;
        this.$message.error("存在已选学段，但没有对应年级!");
      } else {
        this.aVal = false;
      }
    },
    // 警告年级没有学段
    gradeinfo() {
      // console.log(this.meansHour, "焦点获取");
      if (this.optionGrade == null || this.optionGrade.length == 0) {
        this.$refs.gradeInfoRef.blur();
        this.$message({
          type: "warning",
          message: "请先选择学段信息",
        });
      }
    },
    // 警告学科没有学科类型
    subjectinfo() {
      // console.log(this.meansHour, "焦点获取");
      if (this.optionSbject == null || this.optionSbject.length == 0) {
        this.$refs.subjectInfoRef.blur();
        this.$message({
          type: "warning",
          message: "请先选择学科类型信息",
        });
      }
    },

    //返回
    goback() {
      // console.log("返回");
      this.$confirm("是否确认退出", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.$store.commit("userinfo/editclassRaw", null);
          // this.$store.commit("userinfo/editshowupclass", false);
          this.$router.push({
            path: "/myclass",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //------- 基本信息的数据处理
    //全选学段数据
    selectAlls(val) {
      // console.log(val, "学段");
      if (val.length == 0) {
        this.optionGrade = null;
        return;
      }
      if (val.length > 1) {
        val.sort((a, b) => {
          return a - b;
        });
      }
      let flag = false;
      for (var key of val) {
        //如果有all
        if (key == "all") {
          flag = true;
        }
      }
      if (flag) {
        //先置空数组
        this.infoForm.period = [];
        //所有的选项push到v-model的value的数组
        this.optionPeriod.forEach((item) => {
          this.infoForm.period.push(item.id);
        });
      }
      this.queryGrade(this.infoForm.period);
    },
    //全选年级数据
    selectAll(val) {
      // console.log(val, "val");
      if (val.length > 1) {
        val.sort((a, b) => {
          return a - b;
        });
      }
      let flag = false;
      for (var key of val) {
        //如果有all
        if (key == "all") {
          flag = true;
        }
      }
      if (flag) {
        //先置空数组
        this.infoForm.grade = [];
        //所有的选项push到v-model的value的数组
        this.optionGrade.forEach((item) => {
          this.infoForm.grade.push(item.id);
        });
      }
    },
    //选择学科类型
    selectStyle(val) {
      // console.log(val, "学科类型");
      if (val.length == 0) {
        this.optionSbject = null;
        return;
      }
      this.querySubject(val);
    },

    // ---创建章节

    // 创建新的章节弹框
    createChaper() {
      this.newChaper = true;
      // this.newClassHour = true;
    },
    // 创建章节
    async saveChaper(show) {
      // console.log(this.chaperFrom, "chaperFrom");
      // let content =
      //   "第" + this.chaperArray.cNumber + "章" + ":" + this.chaperArray.catalogueName;
      const { data, status } = await this.$Http.createCatalogue({
        catalogueId: "", // 目录id
        // catalogueName:content,//目录名称
        ...this.chaperFrom,
        catalogueType: 1, //目录类型 1-章节 2-课时
        courseId: this.courseId, //课程id
        fileIdList: [], //文件id
        paramId: 0, //父集id 如果是章节则为0 如果是课时则是章节的id
      });
      if (status === 200) {
        // console.log(data, status, "上传章节");
        this.viewChaper();
        this.clearInput();
        if (show) {
          this.newChaper = false;
        }
      }
    },
    // 取消创建章节
    cencleChaper() {
      this.clearInput();
      this.newChaper = false;
    },
    // 查询章节
    async viewChaper() {
      const { data, status } = await this.$Http.queryCatelogueAndResource({
        courseId: this.courseId,
        catalogueId: null, // 目录id
      });
      if (status == 200) {
        // console.log(data, "查询章节");
        this.chaperArray = data;
      }
    },
    // 编辑章节
    async editChaper(item) {
      // console.log(item, "编辑item");
      this.editinfoChaper = true;
      this.editChaperFrom.catelogueId = item.id;
      this.editChaperFrom.catelogueName = item.catelageueName;
    },
    async editSure() {
      const { data, status } = await this.$Http.updateCatelogue({
        // catelogueId: id, //目录id
        // catelogueName: null, // 目录名称
        ...this.editChaperFrom,
        courseId: this.courseId, // 课程id
        fileList: null, // 目录id
        serviceFlag: 1, // 业务类型 1-编辑,2-删除
      });
      if (status == 200) {
        this.viewChaper();
        this.editinfoChaper = false;
      }
      // console.log(data, status, "编辑章节");
    },
    editCancle() {
      // console.log("重置表单");
      this.editinfoChaper = false;
      this.$refs.chaperRef.resetFields();
    },
    // 删除章节
    async delChaper(id) {
      this.$confirm("确认删除该章节", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status, message } = await this.$Http.updateCatelogue({
            catelogueId: id, //目录id
            catelogueName: null, // 目录名称
            // ...this.chaperFrom,
            courseId: this.courseId, // 课程id
            fileList: null, // 目录id
            serviceFlag: 2, // 业务类型 1-编辑,2-删除
          });
          if (status == 200) {
            this.viewChaper();
            this.$message({
              message: "删除成功",
              type: "success",
            });
            // console.log(data, status, "删除章节");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 重置章节表单
    clearInput() {
      // console.log("重置表单");
      this.$refs.chaperRef.resetFields();
      // this.$refs.chaperRef.clearValidate();
    },

    // 创建课时
    creatHour(id) {
      this.hourTitle = "创建课时";
      this.newClassHour = true;
      this.parentId = id;
    },
    //保存课时
    async saveHour(show) {
      const { data, status } = await this.$Http.createCatalogue({
        catalogueId: "", // 目录id
        // catalogueName:content,//目录名称
        // fileIdList: [], //文件id
        ...this.hourform,
        catalogueType: 2, //目录类型 1-章节 2-课时
        courseId: this.courseId, //课程id
        paramId: this.parentId, //父集id 如果是章节则为0 如果是课时则是章节的id
      });
      if (status === 200) {
        // this.newClassHour = false;
        // console.log(data, status, "上传课时");
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.viewHour(this.parentId);
        this.clearHour();
        if (show) {
          this.newClassHour = false;
        }
      }
    },
    // 编辑课时
    async editHour(item) {
      console.log(item, "编辑课时");
      this.hourTitle = "修改课时";
      this.newClassHour = true;
      this.hourform.catalogueName = item.catelageueName;
      this.classHourId = item.id;
      this.hourform.fileIdList.push(item.videoUrl[0].id);
      this.videoUrl = item.videoUrl[0].url;
    },
    // 编辑保存课时
    async editsaveHour() {
      const { data, status } = await this.$Http.updateCatelogue({
        catelogueId: this.classHourId, //目录id
        catelogueName: this.hourform.catalogueName, // 目录名称
        courseId: this.courseId, // 课程id
        fileList: this.hourform.fileIdList, // 文件id
        serviceFlag: 1, // 业务类型 1-编辑,2-删除
      });
      if (status == 200) {
        this.viewHour(this.parentId);

        this.newClassHour = false;
        this.clearHour();
      }
      // console.log(data, status, "编辑章节");
    },
    // 查看资料
    async viewMeans(items) {
      this.meansData = null;
      console.log(this.classHourId, this.courseId, "查看数组", items);
      this.meansId = items.id;
      const { data, status } = await this.$Http.queryCatalogue({
        catalogueId: items.id,
        courseId: this.courseId, //课程id
        type: 3, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
      });
      if (status == 200) {
        if (data.resource.docementList == undefined) {
          this.meansDialog = false;
          this.$message({
            message: "课时下还没有资料",
            type: "warning",
          });
        } else {
          this.meansDialog = true;
          console.log(data, status, "chankan");
          this.meansData = data.resource.docementList;
        }
      }
    },
    // 查看视频
    viewVideo(url) {
      console.log(url, "视频地址");
      if (url == null) {
        this.$message({
          type: "error",
          message: "暂无视频",
        });
      } else {
        this.viewVideolog = true;
        this.viewVideoUrl = url;
      }
    },
    //删除资料
    async delMeansData(id, index) {
      console.log(this.meansData, "数据", id, index);
      this.meansData.splice(index, 1);
      // return;
      // let ids = [];
      // for (let a = 0; a < this.meansData.length; a++) {
      //   console.log(this.meansData[a].resouceId, "resouceId");
      //   if (this.meansData[a].resouceId !== id) {
      //     ids.push(this.meansData[a].resouceId);
      //   }
      // }
      const { data, status } = await this.$Http.deleteCourseCatelogueResource({
        catelogurId: this.meansId, // 目录id
        courseId: this.courseId, // 课程id
        resourceId: id, // 资源ID
      });
      if (status == 200) {
        console.log(data, status, "查询资料");
        // console.log()
      }
    },
    //删除课时
    async delHour(id, ids) {
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status, message } = await this.$Http.updateCatelogue({
            catelogueId: ids, //目录id
            catelogueName: null, // 目录名称
            // ...this.chaperFrom,
            courseId: this.courseId, // 课程id
            fileList: null, // 目录id
            serviceFlag: 2, // 业务类型 1-编辑,2-删除
          });
          if (status == 200) {
            this.viewHour(id);
            // console.log(data, status, "删除章节");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查询课时
    async viewHour(id) {
      this.parentId = id;
      const { data, status } = await this.$Http.queryCatelogueAndResource({
        courseId: this.courseId,
        catalogueId: id, // 目录id
      });
      if (status == 200) {
        if (data !== null || data.length !== 0) {
          // console.log(data, "查询课程");
          // this.hourArray = data;
          for (let x = 0; x < this.chaperArray.length; x++) {
            if (this.chaperArray[x].id == id) {
              this.$set(this.chaperArray[x], "children", data);
            }
          }
        } else {
          for (let x = 0; x < this.chaperArray.length; x++) {
            // if (this.chaperArray[x].id == id) {
            this.$set(this.chaperArray[x], "children", null);
            // }
          }
          // this.hourArray = null;
        }
      }
    },
    // 取消课时
    cancelHour() {
      this.newClassHour = false;
      this.clearHour();
    },
    // 重置课时表单
    clearHour() {
      this.showFileList = false;
      this.hourform.catalogueName = null;
      this.hourform.fileIdList = [];
      this.videoUrl = null;
    },
    //视频文件
    async hourfileChange(file, fileList) {
      console.log(file, fileList, "文件列表");
      console.log(file, "jlaksfj");
      let suffix = file.name.split(".").pop();
      console.log(suffix, "文件后缀");
      if (suffix !== "mp4") {
        this.$message.error("只允许上传mp4格式的视频");
        return;
      }
      this.tloading = true;
      let from = new FormData();
      from.append("file", file.raw);
      from.append("fileType", 1); //文件类型 0-图片 1-视频 2-文档 3-压缩包
      const { data, status } = await this.$Http.uploadfile(from);
      if (status == 200) {
        this.tloading = false;
        this.hourform.fileIdList = [];
        this.hourform.fileIdList.push(data.id);
        this.videoUrl = data.resourceUrl;
        console.log(data, status, "上传视频", this.hourform.fileIdList);
      }
    },
    // 课时资料
    //课时资料弹框
    meansUpload() {
      this.meansCheaper().then(() => {
        if (this.meansArray == null || this.meansArray.length == 0) {
          this.$message({
            type: "warning",
            message: "请先建立章节和课时",
          });
          return;
        }
        this.addMeans = true;
      });
    },
    // 获取课时资料下的章节信息
    async meansCheaper() {
      const { data, status } = await this.$Http.queryCatelogueAndResource({
        courseId: this.courseId,
        catalogueId: null, // 目录id
      });
      if (status == 200) {
        // console.log(data, "查询章节");
        this.meansArray = data;
      }
    },
    //查询课时
    async creatmeansHour(id) {
      const { data, status } = await this.$Http.queryCatelogueAndResource({
        courseId: this.courseId,
        catalogueId: id, // 目录id
      });
      if (status == 200) {
        // console.log(data, "查询课程");
        this.meansHour = data;
      }
    },
    // 获取文件上传类型
    // createResourse() {
    //   let aa = [
    //     //
    //     {
    //       value: 0,
    //       label: "图片",
    //     },
    //     {
    //       value: 1,
    //       label: "视频",
    //     },
    //     {
    //       value: 2,
    //       label: "文档",
    //     },
    //     {
    //       value: 3,
    //       label: "音频",
    //     },
    //     {
    //       value: 4,
    //       label: "压缩包",
    //     },
    //     {
    //       value: 5,
    //       label: "其他",
    //     },
    //   ];
    //   this.meansResources = aa;
    // },
    // 选择章节
    selectChaper(val) {
      // console.log(val, "选择章节");
      this.creatmeansHour(val);
    },
    // 警告章节没有课时
    meansInfo() {
      // console.log(this.meansHour, "焦点获取");
      if (this.meansHour == null || this.meansHour.length == 0) {
        this.$refs.hourSelectRef.blur();
        this.$message({
          type: "warning",
          message: "请先建立课时",
        });
      }
    },

    // 上传课程文件
    // 确认提交课时文件
    async sureMeans() {
      // if (this.fileList.length == 0) {
      //   this.$message({
      //     type: "warning",
      //     message: "请选择要上传的文件",
      //   });
      //   return;
      // }
      this.arrBB = [];
      this.showFileList = false;
      this.meansloading = true;
      this.postmeans(this.resourceNum);
      // let countNum = 0;
     /* this.fileList.map(async (val) => {
        // let suffix = val.name.split(".").pop();
        // let suffixnum = null;
        // if (suffix == "jpg" || suffix == "png" || suffix=='jpeg') {
        //   this.$message.warning('只支持jpg,png,jpeg格式的图片')
        //   return
        //   suffixnum = 0;
        // } else if (suffix == "mp4" || suffix == "wmv" || suffix == "rmvb") {
        //   suffixnum = 1;
        // } else if (
        //   suffix == "docx" ||
        //   suffix == "xlsx" ||
        //   suffix == "pdf" ||
        //   suffix == "pptx" ||
        //   suffix == "ppt"
        // ) {
        //   suffixnum = 2;
        // } else if (suffix == "zip") {
        //   suffixnum = 4;
        // }
        console.log(this.meansForm.mResources, "文件类型");
        let from = new FormData();
        from.append("file", val.raw);
        // from.append("fileType", this.meansForm.mResources); //文件类型 0-图片 1-视频 2-文档 3-压缩包
        const { data, status } = await this.$Http.uploadfile(from);
        // console.log(data, status, "上传课时资料");
        if (status == 200) {
          resourceNum.push(data.id);
          countNum += 1;
          if (countNum === this.fileList.length) {
            this.postmeans(resourceNum);
          }
        }
      });*/
    },
    // 提交资料
    async postmeans(id) {
      const res = await this.$Http.saveCourseAndResouce({
        catelogurId: this.meansForm.mHour, // 目录id
        courseId: this.courseId, // 课程id
        resourceId: id, // 资源ID
      });
      console.log(res, "上传课时资料");
      if (res.status == 200) {
        this.clearMeans();
        this.meansloading = false;
        this.addMeans = false;
      }
    },
    // 取消
    cancleMeans() {
      this.$confirm("是否确定退出", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log(this.meansForm, "meansForm删除");
          this.showFileList = false;
          this.addMeans = false;
          this.clearMeans();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    clearMeans() {
      // this.$refs["uploadRef"].clearFiles();
      this.fileList = [];
      this.$refs.meansRef.resetFields(); //resetField	对该表单项进行重置，将其值重置为初始值并移除校验结果
      // this.$refs.uploadRef.clearFiles(); //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      // console.log(file, fileList, "已清除文件");
      this.fileList = fileList;
    },
    //
    handleBeforeRemove(file, fileList) {
      return this.$confirm("是否确认移除" + file.name, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log(file, fileList, "清除文件");
          this.$message({
            type: "success",
            message: "删除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          reject(false);
        });
    },
    //文件状态改变时的钩子
    handleChange(file, fileList) {
      console.log(file, fileList, "文件状态改变时的钩子");
      this.fileList = fileList;
    },

    // 创建结束
    async createFinish() {
      console.log(this.chaperArray, "创建结束");
      let from = new FormData();
      from.append("courseId", this.courseId);
      const { data, status } = await this.$Http.receiveCourse(from);
      if (status == 200) {
        console.log(data, status, "完成");
        this.$confirm("确定该课程已完成建立", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/myclass",
            });
            // this.$store.commit("userinfo/editclassRaw", null);
            // this.$store.commit("userinfo/editshowupclass", false);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    // 上一步
    async lastStep() {
      let idlist = [this.courseId];
      const { data, status } = await this.$Http.queryCourselists({
        courseIdList: idlist,
        courseType: 1, //课程类型(1-课程，2-资料)
        sortId: 1, //排序Id
        sortType: 1, //排序类型
      });
      if (status == 200) {
        // console.log(data.list[0], status);
        this.dealClassRaw(data.list[0]);
        this.activeName = "first";
      }
    },
  },
  watch: {
    "infoForm.period": {
      handler(newval, oldval) {
        // console.log(newval, oldval, "监听学段");
        if (newval.length < oldval.length) {
          let set1 = new Set(oldval);
          let set2 = new Set(newval);
          let diffrence = new Set([...set1].filter((x) => !set2.has(x)));
          let num = [...diffrence];
          let aData = this.infoForm.grade;
          let astart = [];
          aData.map((val, i) => {
            if (num == 1 && val == 1) {
              astart.push(i);
            } else if (num == 2 && val > 1 && val < 8) {
              astart.push(i);
            } else if (num == 3 && val > 7 && val < 11) {
              astart.push(i);
            } else if (num == 4 && val > 10 && val < 14) {
              astart.push(i);
            } else if (num == 5 && val > 13 && val < 17) {
              astart.push(i);
            }
          });
          aData.splice(astart[0], astart.length);
        }
      },
      deep: true,
    },
    "infoForm.grade": {
      handler(newval, oldval) {
        // console.log(newval, oldval, "监听年级");
        // console.log(this.infoForm.period, "监听xued");
        if (newval.length < oldval.length) {
          let set1 = new Set(oldval);
          let set2 = new Set(newval);
          let diffrence = new Set([...set1].filter((x) => !set2.has(x)));
          let num = [...diffrence];
          let aData = this.infoForm.period;
          let aend = [];
          newval.map((val, i) => {
            if (num == 1 && val == 1) {
              aend.push(i);
            } else if (num > 1 && num < 8 && val > 1 && val < 8) {
              aend.push(i);
            } else if (num > 7 && num < 11 && val > 7 && val < 11) {
              aend.push(i);
            } else if (num > 10 && num < 14 && val > 10 && val < 14) {
              aend.push(i);
            } else if (num > 13 && num < 17 && val > 13 && val < 17) {
              aend.push(i);
            }
          });
          if (aend.length == 0) {
            let aMessage = null;
            if (num == 1) {
              aMessage = "您已经移除了幼儿教育的最后一个年级";
            } else if (num > 1 && num < 8) {
              aMessage = "您已经移除了小学的最后一个年级";
            } else if (num > 7 && num < 11) {
              aMessage = "您已经移除了初中的最后一个年级";
            } else if (num > 10 && num < 14) {
              aMessage = "您已经移除了高中的最后一个年级";
            } else if (num > 13 && num < 17) {
              aMessage = "您已经移除了职教的最后一个年级";
            }
            this.$message.error(aMessage);
            aData.map((val, i) => {
              if (num == 1 && val == 1) {
                aData.splice(i, 1);
              } else if (num > 1 && num < 8 && val == 2) {
                aData.splice(i, 1);
              } else if (num > 7 && num < 11 && val == 3) {
                aData.splice(i, 1);
              } else if (num > 10 && num < 14 && val == 4) {
                aData.splice(i, 1);
              } else if (num > 13 && num < 17 && val == 5) {
                aData.splice(i, 1);
              }
            });
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    // ...mapState({
    //   classRawId: (state) => state.userinfo.classRawId,
    // }),
  },
};
</script>
<style lang="scss" scoped>
.upclass {
  .upclass_center {
    padding: 10px 20px;
    background: #fff;
    .card {
      ::v-deep .el-tabs__item {
        font-size: 18px;
        font-weight: 600;
      }
      ::v-deep .el-tabs__active-bar {
        width: 70px !important;
      }
      ::v-deep .is-disabled {
        color: #000;
      }
      .el-input {
        width: 500px;
      }
      .el-select {
        width: 500px;
      }
      .show_img {
        width: 200px;
        height: 200px;
        // background-color: #909090;
        img {
          width: 200px;
          height: 200px;
        }
      }
      .el-textarea {
        ::v-deep textarea {
          width: 500px;
          height: 100px;
        }
      }
    }
    .r_tab {
      .el-button {
        margin-bottom: 20px;
      }
      .p_minul {
        min-height: 500px;
        max-height: 700px;
        overflow-y: auto;
      }
      .p_ulbtn {
        padding: 0px 20px;
        .p_ul {
          display: flex;
          justify-content: space-between;
          // width: 1500px;
          // height: 88px;
          padding: 15px 18px;
          background: rgba(25, 119, 249, 0.06);
          // border-radius: 4px 4px 4px 4px;
          // opacity: 1;

          .p_title {
            cursor: pointer;
            // font-size: 20px;
            // font-weight: 600;
            // width: 640px;
            // height: 21px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 600;
            color: #141516;
            // line-height: 19px;
            .p_i_icon {
              display: flex;
              justify-content: flex-start;
              i {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin-top: 4%;
                margin-left: 5px;
              }
              .upicon {
                background: url("../../../../assets/userinfo/class/up.png")
                  no-repeat;
              }
              .downicon {
                background: url("../../../../assets/userinfo/class/down.png")
                  no-repeat;
              }
            }
            .p_btns {
              display: flex;
              justify-content: flex-start;
              margin-top: 15px;
              p {
                margin: 0 15px 0 10px;
                cursor: pointer;
              }
              p:hover {
                span {
                  color: rgba(25, 119, 249, 0.5);
                }
              }
            }
            .editicon {
              display: inline-block;
              width: 19px;
              height: 18px;
              margin-right: 3px;
              background: url("../../../../assets/userinfo/class/three.png")
                no-repeat;
              vertical-align: middle;
            }
            .createicon {
              display: inline-block;
              width: 13px;
              height: 14px;
              margin-right: 3px;
              background: url("../../../../assets/userinfo/class/add.png")
                no-repeat;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
              // width: 28px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #1977f9;
              // line-height: 16px;
            }
          }
          .p_btn {
            cursor: pointer;
            // width: 28px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #eb5757;
            // line-height: 16px;
            .delicons {
              display: inline-block;
              width: 19px;
              height: 18px;
              vertical-align: middle;
              background: url("../../../../assets/userinfo/class/four.png")
                no-repeat;
            }

            span {
              vertical-align: middle;
            }
            span:hover {
              color: rgba(235, 87, 87, 0.5);
            }
          }
        }
        .p_li {
          display: flex;
          justify-content: space-between;
          padding: 20px 18px 0 20px;
          .p_lll {
            .p_titles {
              // font-size: 14px;
              // font-weight: 600;
              // width: 345px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 16px;
            }
            .lll_icon {
              display: flex;
              justify-content: flex-start;
              padding: 15px 0px;
              p {
                cursor: pointer;
                margin: 0 15px 0 10px;
                i {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  vertical-align: middle;
                }
                .one_icon {
                  background: url("../../../../assets/userinfo/class/one.png");
                }
                .two_icon {
                  background: url("../../../../assets/userinfo/class/two.png");
                }
                .three_icon {
                  background: url("../../../../assets/userinfo/class/three.png");
                }
                span {
                  // width: 56px;
                  // height: 18px;
                  margin-left: 3px;
                  font-size: 14px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #1977f9;
                  // line-height: 16px;
                  vertical-align: middle;
                }
              }
            }
          }
          .p_d_btns {
            p {
              cursor: pointer;
              // width: 28px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #eb5757;
              // line-height: 16px;
              i {
                display: inline-block;
                width: 19px;
                height: 18px;
                vertical-align: middle;
                // margin-right: 5px;
              }
              .deicon {
                background: url("../../../../assets/userinfo/class/four.png");
              }
              span {
                vertical-align: middle;
              }
            }
            p:hover {
              color: rgba(235, 87, 87, 0.5);
            }
          }
        }
      }
      .p_footer_btns {
        display: flex;
        justify-content: flex-start;
        .el-button {
          margin: 20px 10px 0;
        }
      }
    }
  }
}

//创建章节信息
.chaper_menu {
  .chaper_center {
    .first_input {
      width: 50px;
    }
    .second_input {
      width: 200px;
    }
  }
}
// 创建课时信息
.chour_menu {
  .chour_center {
    .second_input {
      width: 150px;
    }
  }
}
// 课时资料
.cmeans_menu {
  .means_center {
    .el-select {
      width: 350px;
    }
  }
}
// 查看课时资料
.cmeans_dialog {
  .box-card {
    .card_item {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      p {
        font-size: 16px;
        font-weight: 600;
      }
      .delicon {
        display: inline-block;
        width: 18px;
        height: 19px;
        background: url("../../../../assets/userinfo/class/four.png") no-repeat;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
.videomeans {
  .videolog {
    display: flex;
    justify-content: center;
  }
}
</style>
